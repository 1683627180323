export enum LocationType {
    INDEPENDENT_RESTAURANT = 'independent_restaurant',
    GROUPE_OF_RESTAURANTS = 'groupe_of_restaurants',
    HOTEL_RESTAURANT = 'hotel_restaurant',
    HOTEL = 'hotel',
    BAKERY_PASTRY = 'bakery_pastry',
    COCKTAIL_BAR = 'cocktail_bar',
    CATERER = 'caterer',
    AGENCY_OR_COMMUNITY_MANAGER = 'agency_or_community_manager',
    TECH_PARTNER = 'tech_partner',
    INFLUENCER = 'influencer',
    OTHER = 'other',
}

export enum NumberOfLocations {
    ONE = 'one',
    TWO_TO_FIVE = 'two_to_five',
    SIX_TO_TEN = 'six_to_ten',
    ELEVEN_TO_TWENTY = 'eleven_to_twenty',
    TWENTY_ONE_TO_FIFTY = 'twenty_one_to_fifty',
    FIFTY_TO_ONE_HUNDRED = 'fifty_to_one_hundred',
    MORE_THAN_ONE_HUNDRED = 'more_than_one_hundred',
}
