import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, Observable, switchMap, take } from 'rxjs';

import { isNotNil, Role } from '@malou-io/package-utils';

import { AuthService } from ':core/auth/auth.service';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { JimoSupportedLanguages, JimoType, thirdPartyLinks } from ':core/constants';
import { ExperimentationService } from ':core/services/experimentation.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { LOGOUT_ACTION } from ':core/storage.metareducer';
import * as jimoActions from ':modules/jimo/jimo.actions';
import { NotificationsComponent } from ':modules/notification-center/notifications.component';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { UsersService } from ':modules/user/users.services';
import { MenuSelectOption } from ':shared/components/menu-select/menu-select.component';
import { SidenavToggleButtonComponent } from ':shared/components/sidenav-toggle-button/sidenav-toggle-button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { LocalStorageKey } from '../../enums/local-storage-key';
import { MenuSelectComponent } from '../menu-select/menu-select.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
        MatIconModule,
        MatMenuModule,
        MenuSelectComponent,
        TranslateModule,
        SidenavToggleButtonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        NotificationsComponent,
    ],
})
export class HeaderComponent {
    @Input() showLogo = true;
    @Input() showRouting = true;
    @Input() showLangSelection = true;
    @Input() showProfile = true;
    @Input() showSidenavToggle = false;
    @Input() background = 'bg-malou-color-background-light';

    readonly SvgIcon = SvgIcon;
    readonly Role = Role;
    readonly REFERRAL_LINK = thirdPartyLinks.REFERRAL_LINK;

    extensionJobs = 0;
    userInfos$: Observable<User | null>;
    langs: MenuSelectOption[];
    currentLang: string;

    constructor(
        private readonly _auth: AuthService,
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _spinnerService: SpinnerService,
        private readonly _usersService: UsersService,
        private readonly _router: Router,
        public readonly experimentationService: ExperimentationService,
        public readonly notificationCenterContext: NotificationCenterContext
    ) {
        this.userInfos$ = this._store.select(selectUserInfos);
        this.currentLang = this._translate.currentLang;

        this.langs = this._translate.getLangs().map((lang) => ({
            value: lang,
            viewValue: this.getDisplayedLang(lang),
        }));
    }

    changeLang(event: any): void {
        this._spinnerService.show();
        localStorage.setItem(LocalStorageKey.LANG, event.value);
        if (JimoSupportedLanguages.includes(event.value)) {
            (window as JimoType).jimo?.push(['set', 'core:language', [event.value]]);
        }
        this.userInfos$
            .pipe(
                take(1),
                filter(isNotNil),
                switchMap((userInfos) => this._usersService.update(userInfos._id, { defaultLanguage: event.value }))
            )
            .subscribe({
                next: (res) => {
                    this._store.dispatch(UserActions.editUserInfos({ infos: new User(res.data) }));
                    location.reload();
                },
                error: (err) => {
                    console.warn('err updating user language :>>', err);
                    this._spinnerService.hide();
                },
            });
        this._store.dispatch(jimoActions.updateLanguage({ language: event.value }));
    }

    getDisplayedLang(lang: string): string {
        return this._translate.instant(`header.langs.${lang}`);
    }

    logout(): void {
        this._auth.logout$().subscribe(() => {
            localStorage.removeItem('jwtToken');
            window.sessionStorage.clear();
            this._store.dispatch({ type: LOGOUT_ACTION });
            void this._router.navigate(['auth/login']);
        });
    }

    displaySelectedOption(option: string): string {
        return option.toUpperCase();
    }

    navigateTo(url: string): void {
        this._router.navigate([url]);
    }
}
