import { z } from 'zod';

import { NotificationType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export const notificationIdParamsValidator = z
    .object({
        notification_id: objectIdValidator,
    })
    .transform((data) => ({
        notificationId: data.notification_id,
    }));

export type NotificationIdParamsDto = z.infer<typeof notificationIdParamsValidator>;

//-----------------------------------------------------------------------------------

export const createNotificationBodyValidator = z.object({
    categories: z.array(objectIdValidator).optional(),
    endDate: z.coerce.date().optional(),
    hasBeenShownTo: z.array(objectIdValidator),
    isActive: z.boolean(),
    message: z.object({
        en: z.string().nullish(),
        fr: z.string().nullish(),
    }),
    restaurants: z.array(objectIdValidator).optional(),
    startDate: z.coerce.date(),
    title: z.object({
        en: z.string().nullish(),
        fr: z.string().nullish(),
    }),
});

export type CreateNotificationBodyDto = z.infer<typeof createNotificationBodyValidator>;

//-----------------------------------------------------------------------------------

export const updateNotificationBodyValidator = z.object({
    categories: z.array(objectIdValidator).optional(),
    endDate: z.coerce.date().optional(),
    hasBeenShownTo: z.array(objectIdValidator).optional(),
    isActive: z.boolean().optional(),
    message: z
        .object({
            en: z.string().nullish(),
            fr: z.string().nullish(),
        })
        .optional(),
    restaurants: z.array(objectIdValidator).optional(),
    startDate: z.coerce.date().optional(),
    title: z
        .object({
            en: z.string().nullish(),
            fr: z.string().nullish(),
        })
        .optional(),
});

export type UpdateNotificationBodyValidator = z.infer<typeof updateNotificationBodyValidator>;

// ----------------------------------------------------------

export const notificationsEmailOpenedQueryValidator = z.object({
    notificationId: objectIdValidator,
    receiverEmail: z.string(),
    type: z.nativeEnum(NotificationType),
});

export type NotificationsEmailOpenedQueryDto = z.infer<typeof notificationsEmailOpenedQueryValidator>;
